




import { defineComponent } from '@vue/composition-api'
import { useSpotifyConnect } from '@/hooks/useSpotify'
import { useActions } from 'vuex-composition-helpers'
import { RootActions } from '@/store'

export default defineComponent({
  name: 'SpotifyCallback',
  setup(_, { root }) {
    const { setHasMusic } = useActions<RootActions>(['setHasMusic'])
    setHasMusic(true)
    useSpotifyConnect(root.$router.currentRoute.hash)
    root.$router.replace({ name: 'Home' })
  }
})
